import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StoreContext } from '../contexts/StoreContext';
import { ServiceContext } from '../contexts/ServiceContext';
import { Loading } from './Loading';

export const Authorization = (props) => {
	const { authenticationService , authorizationService, userService, accessService, companyService, machineService } = useContext(ServiceContext);
	const { state, dispatch } = useContext(StoreContext);
	let { isAuthorized, loggedInUser } = state;

	const { children } = props;
	const navigate = useNavigate();
	const location = useLocation();

	const privateRoutes = [
		'/dashboard',
		'/profile',
		'/inventory',
		'/serenity-events',
		'/monitoring',
		'/users',
		'/ras',
		'/virtual-appliance',
		'/support',
		'/welcome',
	];
	const routesRequiringAuthorization = {
		'/inventory': 'appliance.list',
		'/serenity-events': 'appliance.list',
		'/monitoring': 'appliance.list',
		'/users': 'user.list',
		'/ras': 'ras.list',
		'/virtual-appliance': 'appliance.list',
		'/support': 'appliance.list',
	};
	const publicOnlyRoutes = ['/authentication', '/sign-up', '/company-account-registration', '/password-recovery']; // if the user is logged in and lands on these routes, redirect him to the dashboard

	useEffect(() => {
		authorize();
	}, [location.pathname]);

	const authorize = async () => {
		let isAuthorized = false;
		let user = loggedInUser ?? null;

		try {
			if (user) {
				isAuthorized = await authorizationService.authorize('company', user?.company?.id, 'central.hub.view');
			} else {
				user = await userService.getLoggedInUser();
				isAuthorized = true;
			}

			const latestOsVersions = await machineService.getOsLatestVersions();
			
			dispatch({
				payload: {
					isAuthorized: isAuthorized === true,
					loggedInUser: isAuthorized === true ? user : null,
					services:
						isAuthorized === true ? companyService.processCompanyServices(user?.company?.services) : null,
					osLatestVersions: 
						isAuthorized === true ? latestOsVersions : null,
				},
			});
		} catch (error) {
			await authenticationService.clearLocalStorage();
			dispatch({
				payload: {
					isAuthorized: false,
					loggedInUser: null,
				}
			});
		}
	};

	const matchDynamicRoute = (routes, pathname) => {
		let match = false;

		routes.forEach((publicRoute) => {
			if (new RegExp(publicRoute).test(pathname)) {
				match = true;
			}
		});

		return match;
	};

	if (isAuthorized === undefined || (isAuthorized && !loggedInUser)) {
		return <Loading />;
	}

	if ((privateRoutes.includes(location.pathname) && isAuthorized === false) || (location.pathname === '/' && !loggedInUser && !isAuthorized)) {
		return navigate('/authentication');
	}

	if (
		(location.pathname === '/') ||
		((publicOnlyRoutes.includes(location.pathname) || matchDynamicRoute(publicOnlyRoutes, location.pathname)) && isAuthorized === true) ||
		(routesRequiringAuthorization[location.pathname] && !accessService.hasPermissionOnAnyResource(routesRequiringAuthorization[location.pathname]))
	) {
		return navigate('/dashboard');
	}

	return children;
};
