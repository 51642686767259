import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from '@syneto/compass-react';
import { ServiceContext } from '../../contexts/ServiceContext';

export const MigrateOs = () => {
	const { restService } = useContext(ServiceContext);
	const [settings, setSettings] = useState({});

	useEffect(() => {
		restService.getUpgradeToOsSettings()
			.then((response) => {
				setSettings(response.data);
			});
	}, []);

	return (
		<div className="mt-5 ms-5">
			<h1 className="text-muted font-weight-light mb-5">Migrate SynetoOS 4 to SynetoOS 5</h1>
			<p className="text-muted font-weight-light">You have purchased one or more license(s) to migrate SynetoOS 4 to SynetoOS 5.</p>
			<p className="text-muted font-weight-light">These resources are available to facilitate the migration process.</p>
			{settings?.os5OvaFile &&
				<Row className="mb-2">
					<Col md={4}><h5 className="text-muted">Download the SynetoOS 5 OVA file</h5></Col>
					<Col><Button role="link" href={settings.os5OvaFile} target="_blank">Download OVA</Button></Col>
				</Row>}

			{settings?.surveyTool &&
			<Row className="mb-2">
				<Col  md={4}><h5 className="text-muted">Download the survey tool for SynetoOS 4</h5></Col>
				<Col><Button role="link" href={settings.surveyTool} target="_blank">Download tool</Button></Col>
			</Row>}

			{settings?.migrationGuide &&
			<Row className="mb-2">
				<Col  md={4}><h5 className="text-muted">Download the migration guide</h5></Col>
				<Col><Button role="link" href={settings.migrationGuide} target="_blank">Download migration guide</Button></Col>
			</Row>}
			{(!settings?.migrationGuide && !settings?.surveyTool && !settings?.os5OvaFile ) && 
				<h4 className="mt-5">No links available.</h4>
			}

		</div>
	);
};
