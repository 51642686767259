export class RestService {
	constructor(restAxios) {
		this.restAxios = restAxios;
	}

	getIsUpgradableToOs5 = (companyId) => {
		return this.restAxios.post('/iam/companies/product-addons/is-active', { company_id: companyId, 'addon_code': 'isUpgradableToOs5' });
	};
    
	getUpgradeToOsSettings = () => {
		return this.restAxios.get('/system/settings/upgrade-to-os5');
	};
}
