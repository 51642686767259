import React, { createContext, useContext } from 'react';
import ApolloClient from 'apollo-boost';
import axios from 'axios';
import { AuthenticationService } from '../services/Authentication';
import { AuthorizationService } from '../services/Authorization';
import { AccessService } from '../services/Access';
import { PolicyService } from '../services/Policy';
import { UserService } from '../services/User';
import { TokenService } from '../services/Token';
import { MachineService } from '../services/Machine';
import { LocationService } from '../services/Location';
import { RasService } from '../services/Ras';
import { SerenityEventService } from '../services/SerenityEvent';
import { SettingsService } from '../services/Settings';
import { DestinationService } from '../services/Destination';
import { CompanyService } from '../services/Company';
import { MonitoringService } from '../services/Monitoring';
import { StoreContext } from './StoreContext';
import { RestService } from '../services/Rest';

export const ServiceContext = createContext();

export const ServiceContextProvider = (props) => {
	const token = localStorage.getItem('authToken');

	const graphqlClient = new ApolloClient({
		uri: window.REACT_APP_API_URL + '/graphql',
		credentials: 'include',
	});

	const backEndAxios = axios.create({
		baseURL: window.REACT_APP_BACKEND_PROXY_URL,
		...( token ? { headers: { 'Authorization': token } } : {})
	}); 

	const restAxios = axios.create({
		baseURL: window.REACT_APP_REST_URL,
		...( token ? { headers: { 'Authorization': token } } : {})
	});

	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;

	const authenticationService = new AuthenticationService(graphqlClient);
	const authorizationService = new AuthorizationService(graphqlClient);
	const accessService = new AccessService(graphqlClient, loggedInUser);
	const policyService = new PolicyService(graphqlClient);
	const userService = new UserService(graphqlClient);
	const tokenService = new TokenService(graphqlClient);
	const machineService = new MachineService(graphqlClient, backEndAxios);
	const locationService = new LocationService(graphqlClient, loggedInUser, accessService);
	const rasService = new RasService(graphqlClient, machineService);
	const serenityEventService = new SerenityEventService(graphqlClient);
	const settingsService = new SettingsService(graphqlClient, backEndAxios);
	const destinationService = new DestinationService(graphqlClient);
	const companyService = new CompanyService();
	const monitoringService = new MonitoringService(backEndAxios);
	const restService = new RestService(restAxios);

	return (
		<ServiceContext.Provider
			value={{
				authenticationService,
				authorizationService,
				accessService,
				policyService,
				userService,
				tokenService,
				machineService,
				locationService,
				rasService,
				serenityEventService,
				settingsService,
				destinationService,
				companyService,
				monitoringService,
				restService
			}}
		>
			{props.children}
		</ServiceContext.Provider>
	);
};
